<template>
  <b-card>
    <div
      v-if="loading"
      class="text-center"
    >
      <b-spinner
        variant="primary"
        label="Text Centered"
      />
    </div>
    <div v-else>
      <div class="d-flex justify-content-end">
        <b-dropdown
          id="dropdown-form"
          ref="dropdown"
          class="mr-1"
          right
          no-flip
          variant="secondary"
          text="Фильтр"
        >
          <b-dropdown-form class="py-1">
            <b-form-group
              label="Кол-во на странице"
              label-for="dropdown-form-per-page"
              @submit.prevent.stop
            >
              <b-form-select
                id="dropdown-form-per-page"
                v-model="filters.perPage"
                size="sm"
                :options="[10, 20, 50, 100, 500]"
              />
            </b-form-group>

            <b-button-group>
              <b-button
                variant="primary"
                size="sm"
                @click="doApplyFilters"
              >
                Применить
              </b-button>
              <b-button
                variant="outline-secondary"
                size="sm"
                @click="doResetFilters"
              >
                Сбросить
              </b-button>
            </b-button-group>
          </b-dropdown-form>
        </b-dropdown>
        <router-link :to="{name: 'brands-create'}">
          <b-button variant="primary">
            Создать
          </b-button>
        </router-link>
      </div>

      <data-table
        :columns="columns"
        :rows.sync="rows"
        :settings="settings"
        :dev-logs="false"
        @updateFilter="doUpdateFilter"
      >
        <template v-slot:table-row="item">
          <span v-if="item.column.field === 'name' && $route.name === 'categories'">
            <router-link :to="{name: 'categories-parent', params: {id: item.row.id}}">
              {{ item.formattedRow[item.column.field] }}
            </router-link>
          </span>
          <span
            v-else-if="item.column.field === 'action'"
            class="float-right"
          >
            <router-link :to="{name: 'brands-update', params: {id: item.row.id}}">
              <b-button
                variant="flat-warning"
                class="btn-icon"
              >
                <feather-icon
                  icon="Edit3Icon"
                  class="text-warning"
                />
              </b-button>
            </router-link>

            <b-button
              variant="flat-danger"
              class="btn-icon"
              @click="objectDelete(item.row.id)"
            >
              <feather-icon
                icon="Trash2Icon"
                class="text-danger"
              />
            </b-button>
          </span>
          <span
            v-else
            v-html="item.formattedRow[item.column.field]"
          />
        </template>
      </data-table>
    </div>
  </b-card>
</template>
<script>
import DataTable from '@/components/DataTable.vue'
import {
  BFormGroup, BCard, BDropdown, BDropdownForm, BButton, BButtonGroup, BFormSelect, BSpinner,
} from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import _ from 'lodash'
import { rowsObjectConverter, settingsObjectConverter } from '@/helpers/dataTableHelper'
import dataTableMixin from '@/mixins/dataTableMixin'

export default {
  name: 'BrandList',
  components: {
    BCard,
    BFormGroup,
    BDropdown,
    BDropdownForm,
    BButton,
    BButtonGroup,
    BFormSelect,
    BSpinner,
    DataTable,
  },
  mixins: [
    dataTableMixin,
  ],
  data() {
    return {
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Название',
          field: 'name',
        },
        {
          label: 'Действия',
          field: 'action',
        },
      ],
    }
  },
  beforeMount() {
    // Собираем пачку промисов для предварительной загрузки
    const promises = []

    promises.push(() => this.doLoadBrands())

    // Пушим первую загрузку данных
    this.loadingPromise(promises)

    // Пушим промисы фильтров, чтоб вызывать методы
    this.filterPromises.push(() => this.doLoadBrands())
  },
  methods: {
    doLoadBrands() {
      return axiosIns({
        method: 'GET',
        url: '/brands',
        params: _.merge(this.filters),
      }).then(response => {
        const responseData = response.data.data
        this.$set(this, 'settings', settingsObjectConverter(responseData))
        this.$set(this, 'rows', rowsObjectConverter(responseData))
      })
    },
    objectDelete(id) {
      this.$swal({
        title: '',
        text: 'Вы уверены что хотите удалить эту запись?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Да',
        cancelButtonText: 'Нет',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axiosIns({
            method: 'DELETE',
            url: `/brands/${id}`,
          }).then(() => {
            this.doLoadBrands()
            this.$swal({
              icon: 'success',
              title: 'Удалено!',
              text: '',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          })
        }
      })
    },
  },
}
</script>
